/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://fmxeqnrwcrfppm7oh5z6cxm3mm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ucetf6vkdjb47d52myjzhskxsa",
    "aws_cognito_identity_pool_id": "us-east-1:7673c8e6-84a1-40c2-a461-76f1c35a5070",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fghl3RPmr",
    "aws_user_pools_web_client_id": "1scnoju0bfb0bkumubgjvajju9",
    "oauth": {
        "domain": "mukjn49hv4k9-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000,https://runhappy.link",
        "redirectSignOut": "http://localhost:3000,https://runhappy.link",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "studiodemo-storage-f02c1813195737-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
